<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Transactions', to: useRoute().build(PublicTransaction.routes().index) }, { title: 'Transaction Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-tabs v-model="tab">
                    <v-tab value="one" rounded="0">Transaction</v-tab>
                    <v-tab value="two" rounded="0">Blocks</v-tab>
                </v-tabs>
                <v-card-text>
                    <v-tabs-window v-model="tab">
                        <v-tabs-window-item value="one">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" sm="3" xl="2">
                                        <ReadOnlyTextField :value="page.props.data.transaction_code" label="Transaction Code" />
                                    </v-col>
                                    <v-col cols="12" sm="3" xl="2">
                                        <ReadOnlyDateTextField :date="page.props.data.execution_date" label="Date" />
                                    </v-col>
                                    <v-col cols="12" sm="3" xl="2">
                                        <ReadOnlyTextField :value="page.props.data.carbon_amount_tco2e" label="Carbon (tCO2e)" />
                                    </v-col>
                                    <v-col cols="12" sm="3" xl="2">
                                        <ReadOnlyTextField :value="page.props.data.steward_name" label="Steward" />
                                    </v-col>
                                    <v-col cols="12" sm="3" xl="2">
                                        <ReadOnlyTextField :value="page.props.data.country" label="Country" />
                                    </v-col>
                                    <v-col v-show="page.props.data.state" cols="12" sm="3" xl="2">
                                        <ReadOnlyTextField :value="page.props.data.state" label="State" />
                                    </v-col>
                                    <v-col cols="12" sm="3" xl="2">
                                        <ReadOnlyTextField :value="page.props.data.type" label="Transaction Type Name" />
                                    </v-col>
                                    <v-col cols="12" sm="3" xl="2">
                                        <ReadOnlyTextField :value="page.props.data.status" label="Status" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tabs-window-item>

                        <v-tabs-window-item value="two">
                            <v-data-table :items="page.props.data.blocks.data" :headers="PublicBlock.headers()">
                                <template v-for="column in dateColumns" :key="column.key" #[`item.${column.key}`]="{ item }">
                                    {{ dateUtil.toISO8601(getValue(item, column.key)) }}
                                </template>
                            </v-data-table>
                        </v-tabs-window-item>
                    </v-tabs-window>
                </v-card-text>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { useRoute } from '@shared/composables/useRoute';
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';
import { PublicBlock } from '@app/purchase/block/models/PublicBlock';
import { useDate } from '@shared/composables/useDate';

const page = PublicTransaction.routes().show.page;

const tab = ref(null);
const dateColumns = computed(() => PublicBlock.headers().filter((column) => column.date));
const dateUtil = useDate();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValue(item: any, key: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return key.split('.').reduce((o, k) => o[k], item);
}
</script>
